<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-10 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="PaperclipIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Facturen</span>
      </template>
      <invoice-tab />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="PercentIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">BTW Tarieven</span>
      </template>
      <vat-tab />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="AwardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Spaarpunten</span>
      </template>
      <points-tab />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import PointsTab from './points/PointsTab.vue'
import VatTab from './vat/VatTab.vue'
import InvoiceTab from './invoices/InvoiceTab.vue'

export default {
  components: {
    InvoiceTab,
    VatTab,
    PointsTab,
    BTabs,
    BTab,
  },
}
</script>
