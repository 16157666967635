<template>
  <b-card>
    <b-row>
      <b-col class="col-lg-8 offset-lg-2">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit()"
            @reset.prevent="resetForm"
          >
            <div
              ref="form"
              class="repeater-form"
            >
              <b-row
                v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
              >
                <b-col md="12">
                  <div class="d-flex border rounded flex-column mb-1 position-relative bg-light">
                    <b-link
                      class="position-absolute position-right-0 zindex-1 sidebar-repeater-delete"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        size="16"
                      />
                    </b-link>
                    <div class="row flex-grow-1 px-2 pt-1">
                      <b-col>
                        <validation-provider
                          #default="validationContext"
                          name="Vat name"
                          rules="required"
                        >
                          <b-form-group
                            label="Vat name"
                            label-for="vat-name"
                          >
                            <b-form-input
                              id="vat-name"
                              v-model="item.name"
                              :state="getValidationState(validationContext)"
                              trim
                            />

                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col>
                        <validation-provider
                          #default="validationContext"
                          name="Percentage"
                          rules="required"
                        >
                          <b-form-group
                            label="Percentage"
                            label-for="vat-percentage"
                          >
                            <b-form-input
                              id="vat-percentage"
                              v-model="item.percentage"
                              :state="getValidationState(validationContext)"
                              trim
                            />

                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </div>
                    <div class="row flex-grow-1 px-2 pb-1">
                      <b-col>
                        <b-form-radio
                          :id="`vat-standard${index}`"
                          name="vat-standard"
                        >
                          Gebruik als standaard
                        </b-form-radio>
                      </b-col>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <b-row>
              <b-col>
                <b-link
                  variant="primary"
                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('settings.sales.vat.add_vat') }}</span>
                </b-link>
              </b-col>
              <b-col class="text-right">
                <b-button
                  variant="primary"
                  @click="save"
                >
                  {{ $t('buttons.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadio, BFormInvalidFeedback, BLink, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import Api from '@/libs/api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vatStoreModule from './vatStoreModule'
import { useToast } from 'vue-toastification/composition'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BFormInvalidFeedback,
    BLink,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const VAT_STORE_MODULE_NAME = 'vat'
    const items = ref([
      {
        vat: {
          name: null,
          percentage: null,
        },
      },
    ])

    // Register module
    if (!store.hasModule(VAT_STORE_MODULE_NAME)) store.registerModule(VAT_STORE_MODULE_NAME, vatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VAT_STORE_MODULE_NAME)) store.unregisterModule(VAT_STORE_MODULE_NAME)
    })

    store.dispatch('vat/fetchData', { }).then(response => {
      if (response.data['hydra:member'].length > 0) {
        items.value = response.data['hydra:member']
      }
    })

    const toast = useToast()
    const save = (ctx, callback) => {
      items.value.forEach(v => {
        console.log(v)
      })
      // Api.post('vats', {
      // })
      //   .then(data => {
      //     callback(data['hydra:member'])
      //   })
      //   .catch(() => {
      //     toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: 'Error fetching users list',
      //         icon: 'AlertTriangleIcon',
      //         variant: 'danger',
      //       },
      //     })
      //   })
    }

    return {
      getValidationState,
      items,
      save,
    }
  },
  methods: {
    removeItem(index) {
      this.items.splice(index, 1)
    },
    repeateAgain() {
      this.items.push({
        vat: {
          name: null,
          percentage: null,
        },
      })
    },
  },
}
</script>

<style>

</style>
