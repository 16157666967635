<template>
  <b-card />
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
  },
}
</script>

<style>

</style>
